<template>
  <div class="content">
    <sub-title>게시판</sub-title>
    <div class="board_list">
      <div class="hd">
        <span class="number">번호</span>
        <span class="title">제목</span>
        <span class="author">작성자</span>
        <span class="time">시간</span>
      </div>
      <div class="item " v-if="item.type === sportsConst.BOARD_TYPE_FREE_BOARD" v-for="(item,index) in boardList"
           :key="index">
        <span class="number">{{ item.id }}</span>
        <router-link tag="span" class="title" :to="{path:'/front/freeboarddetail',query: {id:item.id, t: new Date().getTime()}}">
          {{ item.title }}
          <i class="fa fa-comment-o text-green" v-if="item.commentQuantity > 0"> {{ item.commentQuantity }}</i>
          <i class="fa fa-file-image-o text-skyblue" v-if="item.betIds !== 'empty'"></i>
        </router-link>
        <span class="author">{{ item.user.nickname }}</span>
        <span class="time">{{ item.createTime|datef('MM-DD') }}</span>
      </div>
      <!--페이지-->
      <pagination :page-index="pageNum"
                  :total="total"
                  :page-size="pageSize"
                  @change="pageChange"
                  v-if="boardList.length > 0"></pagination>

<!--      <div class="board_list">-->
<!--        <free-board-writer :bet-ids="betIds" :from="from"-->
<!--                           @saveBoardSuccess="saveBoardSuccess"></free-board-writer>-->
<!--      </div>-->


    </div>

  </div>

</template>


<script>

import {
  getFreeBoard,
} from "@/network/userRequest";
import {postionMixin} from "@/common/mixin";
import sportsConst from "@/common/sportsConst";
import SubTitle from "@/views/afront/zero/SubTitle.vue";
import Pagination from "@/components/pagenation/Pagination.vue";
import FreeBoardWriter from "@/views/afront/notice/FreeBoardWriter.vue";

export default {
  name: "FreeBoard",
  mixins: [postionMixin],
  components: { FreeBoardWriter, Pagination, SubTitle},
  data() {
    return {
      position: "커뮤니티",
      sportsConst,
      from: 0,//게시판에서 게시글 작성
      pageNum: 1,
      pageSize: 20,
      total: 1,
      orderBy: '',
      search: {},
      boardList: [],
      betIds: []
    }
  },
  methods: {
    initBoardList() {
      getFreeBoard(this.pageNum, this.pageSize).then(res => {
        if (res.data.success) {
          this.total = res.data.total
          this.boardList = res.data.data
        }

      })
    },
    //pagination.vue 에서 페이지 클릭 시 emit발송접수 메소드
    saveBoardSuccess() {
      this.initBoardList()
    },
    pageChange(page) {
      this.pageNum = page
      this.initBoardList()
    },
  },
  created() {
    this.initBoardList();
  }
}
</script>

<style scoped>
@import url("../../../assets/css/front/afrontnotice.css");


</style>